import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./contact.module.scss";
import ThemeContext from '../context/ThemeContext'

export default (props) => {
	const { location } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	const gFormID = 2;

	const {
		data: {
			contact,
			contact: {
				title,
				ACFContact: {
					contact: {
						left: {
							blurb,
							insurancesHeading,
							requestAppointmentLink
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading
							},
							contactContent: content,
							email: contactEmail,
							call: contactCall,
							visit: contactVisit,
							showContactForm: showForm,
              contactFormHeading: formHeading = 'Contact Us',
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			locations: {
				nodes: locations
			},
			plaqusterDougie,
			plaqusterCoachMolar,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage
		}
	} = props;

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

      function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
		}

		if( location ){
			sortByDistance();
		}
	}, [location, locations])


	return (
		<Layout hideCtaButton={true}>
			<Seo post={contact} />
			<div className={`${styles.top} hero`}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}


				<div className={styles.dougie}>
					<FluidImage image={plaqusterDougie} loading={"eager"} fadeIn={false} alternativeText={'Dougie the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.coachmolar}>
					<FluidImage image={plaqusterCoachMolar} loading={"eager"} fadeIn={false} alternativeText={'Coach Molar the plaquster, a Leap dental brand character'} />
				</div>

				<div className={styles.left}>
					<h1 className={styles.contactname} dangerouslySetInnerHTML={{__html: title}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h1>
					{ introHeading && <div className={styles.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
				</div>

				<div className={styles.right}>
					<div className={styles.lefttop}>
						<h1 className={styles.contactname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styles.leftbottom}>
						{ blurb && <div className={styles.contactheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styles.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styles.request}>Request appointment</Link> }
					</div>
				</div>
				
			</div>
			<div className={styles.bottom}>
				
				<div className={styles.left}>
					<div className={styles.contactcontent}>
						<div className={styles.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
							<div className={styles.linkcards}>
								<div className={styles.linkcard} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
									{ contactEmail.emailHeadline && <span className={styles.title} dangerouslySetInnerHTML={{__html: contactEmail.emailHeadline }}></span>}
									{ contactEmail.emailContent && <div className={styles.linkemail} dangerouslySetInnerHTML={{__html: contactEmail.emailContent}}></div> }
								</div>
								<div className={styles.linkcard} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
									{ contactCall.callHeadline && <span className={styles.title} dangerouslySetInnerHTML={{__html: contactCall.callHeadline }}></span>}
									{ contactCall.callContent && <div className={styles.linkcall} dangerouslySetInnerHTML={{__html: contactCall.callContent}}></div> }
								</div>
								<div className={styles.linkcard} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
									{ contactVisit.visitHeadline && <span className={styles.title} dangerouslySetInnerHTML={{__html: contactVisit.visitHeadline }}></span>}
									{ contactVisit.visitContent && <div className={styles.linkvisit} dangerouslySetInnerHTML={{__html: contactVisit.visitContent}}></div> }
								</div>
							</div>
							{showForm && (
                <div className={styles.formWrap}>
                  <h2 className={styles.formHeading}>{formHeading}</h2>
                  {nearestLoc?.databaseId && (
                    <iframe 
                      title="Gravity Form" 
                      src={`${process.env.GATSBY_WP_BASE_URL}/gfembed?f=` + gFormID + '&current_location=' + nearestLoc.databaseId + '&current_state=' + nearestLoc?.ACFLocation?.state} 
                      width="100%" height="885" frameBorder="0" className="gfiframe"></iframe>
                  )}
                </div>
              )}
						</div>
					</div>
				</div>

				<div className={styles.right}>
					<div className={styles.insurance}>
						<span className={styles.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styles.insurancebottom} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		contact: wpPage(id: { eq: $id }) {
			id
			title
			ACFContact {
				contact {
					left {
						blurb
						insurancesHeading
						requestAppointmentLink {
							title
							url
							target
						}
					}
					right {
						introContent {
							blurb
							heading
						}
						contactContent
						email {
							emailHeadline
							emailContent
						}
						call {
							callHeadline
							callContent
						}
						visit {
							visitHeadline
							visitContent
						}
						showContactForm
						contactFormHeading
					}
				}
			}
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		plaqusterDougie: file(name: {eq: "dougie"}) {
			childImageSharp {
				fluid(maxWidth: 140){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		plaqusterCoachMolar: file(name: {eq: "coach-molar"}) {
			childImageSharp {
				fluid(maxWidth: 150){
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		appointmentPage: allWpPage(filter: {template: {templateName: {eq: "Appointments Page"}}}) {
			nodes {
			  link
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		insurance: allWpInsurance (sort: {fields: title, order: ASC}) {
			nodes {
			title
					ACFInsurance {
						logo {
							altText
					localFile {
						childImageSharp {
							fluid(maxWidth: 420){
								...GatsbyImageSharpFluid_withWebp
							}
						}
						publicURL
										extension
					}
						}
					}
			}
		}
		locations: allWpLocation(sort: {fields: title, order: ASC}) {
			nodes {
				id
				title
				link
				databaseId
				birdeyeLocation {
					location {
						lat
						lng
					}
				}
				ACFLocation {
					state
				}
			}
		}
	}
`;
